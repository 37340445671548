import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { toast } from 'react-toastify';
import api from '../../../services/API';

export default function UploadFoto() {
  const [enviando, setEnviando] = useState(false);
  const [arquivos, setArquivos] = useState(null);
  const [codigoPromocional, setCodigoPromocional] = useState('');
  const [status, setStatus] = useState('Procurar...');
  const [enviosRestantes, setEnviosRestantes] = useState(null);

  const validarCodigo = async () => {
    if (codigoPromocional) {
      const resultado = await api.get(
        `/api/codigo-promocional/validar?codigo=${codigoPromocional}`
      );

      if (!resultado) {
        return;
      }
    }
  };

  const upload = async (e) => {
    e.preventDefault();

    if (!arquivos) {
      toast.error(`Selecione um arquivos primeiro`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    let formData = new FormData();

    for (let i = 0; i < arquivos.length; i++) {
      formData.append('arquivos', arquivos[i]);
      codigoPromocional &&
        formData.append('codigoPromocional', codigoPromocional);
    }
    setEnviando(true);
    try {
      const resultado = await api.post('/api/upload/venda_foto', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (resultado) {
        toast.success(resultado.data.mensagem, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        
        setEnviosRestantes(resultado.data.enviosRestantes);
        setArquivos(null);
        setStatus('Procurar...');
        setCodigoPromocional('');
      }
    } catch (error) {
      if (error.response?.data?.erro) {
        toast.error(error.response.data.erro, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
      }
    } finally {
      setEnviando(false);
    }
  };

  useEffect(() => {
    if (!arquivos) {
      document.getElementById('arquivos').value = null;
    }
  }, [arquivos]);

  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>ENVIAR NOTA FISCAL</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <form onSubmit={upload} className="form fundo-claro">
          <label htmlFor="arquivos">Nota Fiscal</label>
          <input
            type="file"
            id="arquivos"
            name="arquivos"
            multiple
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(e) => {
              setArquivos(e.target.files);
              e.target.files.length > 0
                ? setStatus(
                    `${e.target.files.length} arquivos(s) selecionado(s)`
                  )
                : setStatus('Procurar...');
            }}
          />
          <input
            id="botao"
            type="button"
            value={status}
            onClick={(e) => {
              document.getElementById('arquivos').click();
            }}
          />
          <label htmlFor="codigoPromocional">
            Insira seu código promocional caso possua
          </label>
          <input
            type="text"
            id="codigoPromocional"
            name="codigoPromocional"
            value={codigoPromocional}
            onChange={(e) => setCodigoPromocional(e.target.value)}
            onBlur={validarCodigo}
          />
          <Row className="mensagemCodigoPromocional d-flex justify-content-center align-items-center text-center mb-3 small px-4 font-weight-bold">
            <span>Amigo pintor, ao enviar uma nota fiscal relacionada a uma revenda master, os pontos serão dobrados automaticamente. Neste caso, os vouchers de pontos em dobro serão cancelados.</span>
          </Row>
          
          {enviosRestantes !== null && (
            <Row className="mensagemEnviosRestantes d-flex justify-content-center align-items-center text-center mb-4 px-4">
              <div className="alert alert-info" role="alert">
                <i className="fas fa-info-circle mr-2"></i>
                <strong>
                  Você ainda possui {enviosRestantes} envios disponíveis nos próximos 30 dias.
                </strong>
              </div>
            </Row>
          )}
          
          <Row className="d-flex justify-content-center align-items-center">
            {enviando ? (
              <button disabled className="btn-enviar" type="submit">
                ENVIANDO...
              </button>
            ) : (
              <button className="btn-enviar" type="submit">
                ENVIAR
              </button>
            )}
          </Row>
        </form>
      </div>
    </Container>
  );
}
