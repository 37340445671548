/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { toast } from 'react-toastify';
import './styles.css';
import { atualizarPerfil } from '../../../store/modules/parceiro/actions';
import api from '../../../services/API';

export default function Perfil() {
  const atualizando = useSelector((state) => state.parceiro.atualizando);
  const dispatch = useDispatch();

  const parceiro = useSelector((state) => {
    console.log('state.parceiro', state.parceiro);
    return state.parceiro;
  });

  const atualizarParceiro = async (e) => {
    e.preventDefault();
    const data = {
      nome,
      sexo,
      dataNascimento,
      email,
      telefone,
      celular,
      endRua,
      endNumero,
      endComplemento,
      endBairro,
      endCidade,
      endEstado,
      endCep,
      indicacaoVendedor,
      indicacaoLoja,
      senhaAntiga,
      senha
    };

    const temNulo = validarCampos(data);
    if (temNulo) {
      return;
    }

    data.endCep = data.endCep.replace(/[^\d]/g, '');
    data.telefone = data.telefone.replace(/[^\d]/g, '');
    data.celular = data.celular.replace(/[^\d]/g, '');
    data.dataNascimento = data.dataNascimento.replace(/[^\d]/g, '');
    data.dataNascimento = `${data.dataNascimento.substring(
      4
    )}${data.dataNascimento.substring(2, 4)}${data.dataNascimento.substring(
      0,
      2
    )}`;

    if (foto && foto.length > 0) {
      const formData = new FormData();
      formData.append('arquivo', foto[0]);
      formData.append('idParceiro', parceiro.id);
      api.post('/api/upload/parceiro_foto', formData).catch((error) => {
        toast.error(error.erro || 'Erro ao atualizar foto de perfil.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
      });
    }

    dispatch(atualizarPerfil(data));
  };

  const [cpf] = useState(parceiro.cpf);
  const [nome, setNome] = useState(parceiro.nome);
  const [sexo, setSexo] = useState(parceiro.sexo);
  const [dataNascimento, setDataNascimento] = useState(
    `${parceiro.dataNascimento.slice(8)}${parceiro.dataNascimento.slice(
      5,
      7
    )}${parceiro.dataNascimento.slice(0, 4)}`
  );
  const [email, setEmail] = useState(parceiro.email);
  const [telefone, setTelefone] = useState(parceiro.telefone);
  const [celular, setCelular] = useState(parceiro.celular);
  const [endRua, setRua] = useState(parceiro.endRua);
  const [endNumero, setNumero] = useState(parceiro.endNumero);
  const [endComplemento, setComplemento] = useState(parceiro.endComplemento);
  const [endBairro, setBairro] = useState(parceiro.endBairro);
  const [endCidade, setCidade] = useState(parceiro.endCidade);
  const [endEstado, setEstado] = useState(parceiro.endEstado);
  const [endCep, setCep] = useState(parceiro.endCep);
  const [senhaAntiga, setSenhaAntiga] = useState('');
  const [senha, setSenha] = useState('');
  const [indicacaoLoja, setIndicacaoLoja] = useState(parceiro.indicacaoLoja);
  const [revendas, setRevendas] = useState([]);
  const [foto, setFoto] = useState();
  const [indicacaoVendedor, setIndicacaoVendedor] = useState(parceiro.indicacaoVendedor);

  const validarCampos = (data) => {
    const entries = Object.entries(data);
    let temNulo = false;
    entries.forEach((param) => {
      if (temNulo) return;
      if (param[1] === '' && param[0] === 'sexo') {
        toast.error(`Sexo obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (
        param[1] === '' &&
        param[0] !== 'endComplemento' &&
        param[0] !== 'telefone' &&
        param[0] !== 'senha' &&
        param[0] !== 'senhaAntiga' &&
        param[0] !== 'email'
      ) {
        const elemento = document.getElementsByName(param[0])[0];
        toast.error(`${elemento.placeholder} obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
    });
    return temNulo;
  };

  const buscarCep = async (element) => {
    let input = element.target;
    let cep = input.value.replace(/[^\d]/g, '');
    if (!cep) return;
    const resultado = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
    if (!resultado || resultado.data.erro) {
      toast.error('CEP inválido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCep('');
    } else {
      setRua(resultado.data.logradouro);
      setBairro(resultado.data.bairro);
      setCidade(resultado.data.localidade);
      setEstado(resultado.data.uf);
      setComplemento(resultado.data.complemento);
    }
  };

  const validarTelefoneCelular = async (e) => {
    const valor = e.target.value.replace(/[^\d]/g, '');
    if (!valor) return;
    if (valor.length < 10) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      e.target.placeholder === 'Telefone' ? setTelefone('') : setCelular('');
    }
  };

  const validarDataNascimento = async (e) => {
    const data = e.target.value.replace(/[^\d]/g, '');
    if (!data) return;
    if (data.length < 8) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setDataNascimento('');
    }
  };

  const buscarRevendas = async () => {
    const resultado = await api.get('/api/revendas');
    setRevendas(resultado.data.revendas);
  };

  useEffect(() => {
    buscarRevendas();
  }, []);

  const [hover, setHover] = useState(false);
  const [imgUrl, setImgUrl] = useState(
    `${process.env.REACT_APP_API_BASE_URL}/arquivos/${parceiro.foto}`
  );

  useEffect(() => {
    console.log('imgUrl: ', imgUrl);
  }, [imgUrl]);
  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>MEU PERFIL</h5>
        <hr className="hr-loja mt-0" />
      </div>
      {parceiro.foto && (
        <div>
          <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
              document.getElementById('file').click();
            }}
            style={{
              height: 200,
              width: 200,
              borderRadius: '100%',
              position: 'absolute',
              opacity: hover ? 0.5 : 0,
              backgroundColor: '#000',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
              gap: 8,
            }}
          >
            <i class="fas fa-pen"></i>
            <span>editar</span>
          </div>
          <img
            src={imgUrl}
            alt="profile img"
            width={200}
            height={200}
            style={{ borderRadius: '100%', objectFit: 'cover' }}
          />
          <input
            id="file"
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => {
              if (e.target.files.length > 0) {
                setFoto(e.target.files);
                setImgUrl(
                  e.target.files.length > 0 &&
                  URL.createObjectURL(e.target.files[0])
                );
              }
            }}
          />
        </div>
      )}
      <div>
        <form
          onSubmit={atualizarParceiro}
          className="form loja fundo-claro justify-content-center align-items-center"
        >
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="cpf">CPF</label>
              <InputMask
                id="cpf"
                name="cpf"
                type="text"
                placeholder="CPF"
                value={cpf}
                mask="999.999.999-99"
                disabled
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={8}>
              <label htmlFor="nome">Nome</label>
              <input
                id="nome"
                name="nome"
                type="text"
                placeholder="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6} md={3}>
              <label htmlFor="dataNascimento">Data de Nascimento</label>
              <InputMask
                id="dataNascimento"
                name="dataNascimento"
                type="text"
                placeholder="Data de Nascimento"
                value={dataNascimento}
                onBlur={validarDataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
                mask="99/99/9999"
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6} md={3}>
              <label htmlFor="sexo">Sexo</label>
              <select
                id="sexo"
                name="sexo"
                value={sexo}
                onChange={(e) => setSexo(e.target.value)}
                placeholder="Sexo"
              >
                <option disabled value="">
                  Selecione o sexo
                </option>
                <option value="F">Feminino</option>
                <option value="M">Masculino</option>
              </select>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6} md={3}>
              <label htmlFor="celular">Celular</label>
              <InputMask
                id="celular"
                name="celular"
                type="text"
                placeholder="Celular"
                value={celular}
                mask="(99) 99999-9999"
                onChange={(e) => setCelular(e.target.value)}
                onBlur={validarTelefoneCelular}
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6} md={3}>
              <label htmlFor="telefone">Telefone</label>
              <InputMask
                id="telefone"
                name="telefone"
                type="text"
                placeholder="Telefone"
                value={telefone}
                mask="(99) 9999-9999"
                onChange={(e) => setTelefone(e.target.value)}
                onBlur={validarTelefoneCelular}
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12}>
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endRua">Endereço</label>
              <input
                id="endRua"
                name="endRua"
                type="text"
                placeholder="Endereço"
                value={endRua}
                onChange={(e) => setRua(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="endNumero">Número</label>
              <input
                id="endNumero"
                name="endNumero"
                type="text"
                placeholder="Número"
                value={endNumero}
                onChange={(e) => setNumero(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="endComplemento">Complemento</label>
              <input
                id="endComplemento"
                name="endComplemento"
                type="text"
                placeholder="Complemento"
                value={endComplemento}
                onChange={(e) => setComplemento(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="endBairro">Bairro</label>
              <input
                id="endBairro"
                name="endBairro"
                type="text"
                placeholder="Bairro"
                value={endBairro}
                onChange={(e) => setBairro(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={4} md={3}>
              <label htmlFor="endCidade">Cidade</label>
              <input
                id="endCidade"
                name="endCidade"
                type="text"
                placeholder="Cidade"
                value={endCidade}
                onChange={(e) => setCidade(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={4} md={3}>
              <label htmlFor="endEstado">Estado</label>
              <input
                id="endEstado"
                name="endEstado"
                type="text"
                placeholder="Estado"
                value={endEstado}
                onChange={(e) => setEstado(e.target.value)}
                maxLength={2}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={4} md={3}>
              <label htmlFor="endCep">CEP</label>
              <InputMask
                id="endCep"
                name="endCep"
                type="text"
                placeholder="CEP"
                mask="99999-999"
                onBlur={buscarCep}
                value={endCep}
                onChange={(e) => setCep(e.target.value)}
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} md={6}>
              <label htmlFor="indicacaoVendedor">Indicado por algum vendedor?</label>
              <input
                id="indicacaoVendedor"
                name="indicacaoVendedor"
                type="text"
                placeholder="Código do Vendedor"
                value={indicacaoVendedor}
                onChange={(e) => setIndicacaoVendedor(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} md={6}>
              <label htmlFor="indicacaoLoja">Indicado por alguma loja?</label>
              <select
                id="indicacaoLoja"
                name="indicacaoLoja"
                value={indicacaoLoja}
                onChange={(e) => setIndicacaoLoja(e.target.value)}
              >
                <option disabled value="">
                  {parceiro.indicacaoLoja !== null ? parceiro.indicacaoLoja : 'Selecione uma loja'}
                </option>
                {revendas.map((l, i) => (
                  <option value={l.nomeInput} key={l.nomeInput}>
                    {l.nomeInput}
                  </option>
                ))}
                <option value=""> </option>
              </select>
            </Col>
            <Col className="d-flex flex-column mt-4" xs={12}>
              <h6>ALTERAÇÃO DE SENHA</h6>
              <hr className="mt-0 mb-3" />
            </Col>
            <Col className="d-flex flex-column" xs={12} md={6}>
              <label htmlFor="senhaAntiga">Senha Antiga</label>
              <input
                id="senhaAntiga"
                name="senhaAntiga"
                type="password"
                placeholder="Deixe em branco para não alterar"
                value={senhaAntiga}
                onChange={(e) => setSenhaAntiga(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} md={6}>
              <label htmlFor="senha">Nova Senha</label>
              <input
                id="senha"
                name="senha"
                type="password"
                placeholder="Deixe em branco para não alterar"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              ></input>
            </Col>
          </Row>

          <div className={'col-md-4 col-md-offset-4 col-offset-4'}>
            {atualizando ? (
              <button disabled className="btn-atualizar w-100" type="submit">
                ATUALIZANDO...
              </button>
            ) : (
              <button className="btn-atualizar w-100" type="submit">
                ATUALIZAR
              </button>
            )}
          </div>
        </form>
      </div>
    </Container>
  );
}
