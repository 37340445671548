/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from '../../../services/API';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';
import {
  resgatarPremio,
  atualizarPerfil,
  getParceiro,
} from '../../../store/modules/parceiro/actions';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import { ConfimarEndereco } from './ConfimarEndereco';
import ModalMidia from '../ModalMidia';

const settings = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  swipeToSlide: true,
};

export default function DetalhePremio() {
  const dispatch = useDispatch();
  const { codigo } = useParams();
  const [premios, setPremios] = useState([]);
  const resgatando = useSelector((state) => state.parceiro.resgatando);
  const cartao = useSelector((state) => state.parceiro.cartao);

  const parceiro = useSelector((state) => state.parceiro);
  const [premio, setPremio] = useState({
    id: '',
    nome: '',
    codigo: '',
    descricao: '',
    pontos: '',
    pontosAlternativo: '',
    diretorioFoto: '',
    tipo: '',
  });

  const [pontosBase, setPontosBase] = useState(0);
  const [qtde, setQtde] = useState(1);
  const [celularRecarga, setCelularRecarga] = useState('');
  const [operadoraRecarga, setOperadoraRecarga] = useState('');
  const [voltagem, setVoltagem] = useState('');
  const [nomeCompleto, setNomeCompleto] = useState('');

  const [tamanhoCamiseta, setTamanhoCamiseta] = useState('');
  const [tamanhoBermuda, setTamanhoBermuda] = useState('');
  //const [tamanhoJaqueta, setTamanhoJaqueta] = useState('');
  const [tamanhoCalcado, setTamanhoCalcado] = useState('');
  const [opcaoKit184Camiseta, setOpcaoKit184Camiseta] = useState('');
  const [opcaoKit184Calca, setOpcaoKit184Calca] = useState('');
  const [opcaoKit184Bermuda, setOpcaoKit184Bermuda] = useState('');
  const [opcaoKit185Camiseta, setOpcaoKit185Camiseta] = useState('');
  const [opcaoKit185Calca, setOpcaoKit185Calca] = useState('');
  const [opcaoKit185Jaqueta, setOpcaoKit185Jaqueta] = useState('');
  const [gramagemDisco, setGramagemDisco] = useState('');

  const [confirmar, setConfirmar] = useState(false);

  const [avaliacoes, setAvaliacoes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedPicture, setSelectedPicture] = useState('');
  const [permitidoAvaliar, setPermitidoAvaliar] = useState(false);

  const getGramagemOptions = (tipo) => {
    const opcoes = {
      disco1: ['40#', '80#', '100#', '120#', '150#', '180#', '220#'],
      disco2: ['80#', '100#', '120#'],
      disco3: ['150#', '180#', '220#', '240#', '320#', '400#', '500#', '600#'],
      disco4: ['80#', '100#', '150#', '180#', '220#', '240#', '320#', '400#', '500#', '600#'],
      disco5: ['800#', '1000#', '1200#', '1500#', '2000#'],
      disco6: ['40#', '80#', '100#', '120#', '180#', '220#', '240#'],
    };

    return opcoes[tipo] || ['40#', '80#', '100#', '120#', '150#', '180#', '220#'];
  };

  const buscarPremios = async () => {
    const resultado = await api.get(`/api/premios?ativo=1`);
    if (resultado) {
      setPremios(resultado.data.premios);
    }
  };

  const buscarAvaliacoes = async () => {
    const resultado = await api.get(
      `/api/avaliacoes_premio?idPremio=${codigo}`
    );
    if (resultado) {
      setAvaliacoes(resultado.data);
    }
  };

  const loadPermitidoAvaliar = async () => {
    const resultado = await api.post('/api/permitido_avaliar', {
      idPremio: codigo,
    });

    if (resultado && resultado.data) {
      setPermitidoAvaliar(resultado.data.permitido_avaliar);
    }
  };

  const resgatar = async (enderecoEntrega) => {
    let obs = null;
    if (premio.tipo === 'recarga') {
      if (!operadoraRecarga) {
        toast.error('Selecione a operadora', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        setConfirmar(false);
        return;
      }
      if (!celularRecarga) {
        toast.error('Informe o celular', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        setConfirmar(false);
        return;
      }
      obs = `${operadoraRecarga} - ${celularRecarga}`;
    }

    switch (premio.tipo) {
      case 'bivolt':
        obs = voltagem;
        break;
      case 'camiseta':
        obs = tamanhoCamiseta;
        break;
      case 'bermuda':
        obs = tamanhoBermuda;
        break;
      //case 'jaqueta':
      // obs = tamanhoJaqueta;
      // break;
      case 'calcado':
        obs = tamanhoCalcado;
        break;
      case 'kit':
        if (premio.codigo === 184) {
          if (
            !opcaoKit184Camiseta ||
            !opcaoKit184Calca ||
            !opcaoKit184Bermuda
          ) {
            toast.error('Alguma opção de produto não foi selecionada', {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 10000,
            });
            setConfirmar(false);
            return;
          } else {
            obs = `Camisetas = ${opcaoKit184Camiseta}, Calça = ${opcaoKit184Calca}, Bermudas = ${opcaoKit184Bermuda}`;
          }
        }
        if (premio.codigo === 185) {
          if (
            !opcaoKit185Camiseta ||
            !opcaoKit185Calca ||
            !opcaoKit185Jaqueta
          ) {
            toast.error('Alguma opção de produto não foi selecionada', {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 10000,
            });
            setConfirmar(false);
            return;
          } else {
            obs = `Camisetas = ${opcaoKit185Camiseta}, Calças = ${opcaoKit185Calca}, Jaquetas = ${opcaoKit185Jaqueta}`;
          }
        }

        break;
      case 'disco':
      case 'disco1':
      case 'disco2':
      case 'disco3':
      case 'disco4':
      case 'disco5':
      case 'disco6':
        obs = gramagemDisco;
        break;
      default:
        break;
    }

    if (premio.tipo === 'credito' && !cartao) {
      if (!nomeCompleto) {
        toast.error('Informe seu nome completo', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        setConfirmar(false);
        return;
      }
      obs = nomeCompleto;
    }

    if (premio.tipo && premio.tipo !== 'credito' && !obs) {
      let mensagemErro = 'Opção do produto não selecionado';
      if (premio.tipo.startsWith('disco')) {
        mensagemErro = 'Por favor, selecione a gramagem do disco';
      }
      toast.error(mensagemErro, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setConfirmar(false);
      return;
    }

    setConfirmar(false);
    dispatch(
      resgatarPremio({
        codigoPremio: premio.codigo,
        qtde,
        obs,
        enderecoEntrega,
      })
    );

    dispatch(getParceiro());
  };

  const alterarVoltagem = (valor) => {
    setVoltagem(valor);
    valor === '110V'
      ? setPontosBase(premio.pontosWeb)
      : setPontosBase(premio.pontosAlternativo);
  };

  const addQtde = (e) => {
    e.preventDefault();
    setQtde(qtde + 1);
  };

  const subQtde = (e) => {
    if (qtde === 1) return;
    e.preventDefault();
    setQtde(qtde - 1);
  };

  const validarCelular = async (e) => {
    const valor = e.target.value.replace(/[^\d]/g, '');
    if (!valor) return;
    if (valor.length < 10) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCelularRecarga('');
    }
  };

  const buscarPremio = async () => {
    const resultado = await api.get(`/api/premios?codigo=${codigo}&ativo=1`);

    if (resultado) {
      if (resultado.data.premios[0].isCombo) {
        const combo = await api.get(
          `/api/premios/${resultado.data.premios[0].id}`
        );
        setPremio(combo.data);
        setPontosBase(combo.data.pontosWeb);
        return;
      }

      setPremio(resultado.data.premios[0]);
      setPontosBase(resultado.data.premios[0].pontosWeb);
    }
  };

  const updateUser = (endereco, completo) => {
    if (!completo) dispatch(atualizarPerfil(endereco));
  };

  useEffect(() => {
    buscarPremio();
    buscarPremios();
    buscarAvaliacoes();
    loadPermitidoAvaliar();
  }, []);

  return (
    <Container className="mt-5 mb-5">
      {!confirmar && !resgatando ? (
        <Row>
          <Col xs={12} lg={5} className="p-3">
            <img
              className="w-100"
              alt={premio.nome}
              src={premio.diretorioFoto}
            ></img>
          </Col>
          <Col
            xs={12}
            lg={7}
            id="detalheProduto"
            name={premio.codigo}
            className="p-3"
          >
            <Col>
              <span id="nomeProduto" className="premio-titulo">
                {premio.nome}
              </span>
            </Col>
            <br />
            <Col>
              <span id="valorProduto" className="premio-subtitulo">
                <b>
                  {Number(pontosBase * qtde).toLocaleString('pt-BR')} pontos{' '}
                </b>
              </span>
            </Col>
            <br />
            <Col>
              {premio.isCombo ? (
                <div
                  style={{
                    fontSize: '20px',
                    color: '#fff',
                    width: '280px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '12px',
                    background: '#5b87ca',
                  }}
                >
                  <span>
                    <strong>{premio.quantidade}</strong> Disponíveis
                  </span>
                </div>
              ) : (
                <span className="premio-status">
                  Disponível <i className="fas fa-check"></i>
                </span>
              )}
            </Col>
            <br />
            <Col>
              <span className="premio-subtitulo">
                <b>Descrição</b>
              </span>
            </Col>
            <Col className="text-justify">
              <span
                className="d-inline-block premio-subtitulo"
                dangerouslySetInnerHTML={{ __html: premio.descricao }}
              ></span>
            </Col>
            <br />
            {/* {!premio.isCombo && (
              <>
                <Col className="text-justify">
                  <button className="btn-sub-qtde m-1" onClick={subQtde}>
                    <i className="fas fa-minus"></i>
                  </button>
                  <span className="premio-subtitulo m-3">
                    <b>{qtde}</b>
                  </span>
                  <button className="btn-add-qtde m-1" onClick={addQtde}>
                    <i className="fas fa-plus"></i>
                  </button>
                </Col>
                <br />
              </>
            )} */}
            <Col className="text-justify">
              <button className="btn-sub-qtde m-1" onClick={subQtde}>
                <i className="fas fa-minus"></i>
              </button>
              <span className="premio-subtitulo m-3">
                <b>{qtde}</b>
              </span>
              <button className="btn-add-qtde m-1" onClick={addQtde}>
                <i className="fas fa-plus"></i>
              </button>
            </Col>
            <br />
            {premio.tipo === 'recarga' && (
              <form className="form loja fundo-claro">
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="operadora">Operadora</label>
                  <select
                    id="operadora"
                    name="operadora"
                    placeholder="Operadora"
                    value={operadoraRecarga}
                    onChange={(e) => setOperadoraRecarga(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione a operadora
                    </option>
                    <option value="Vivo">Vivo</option>
                    <option value="Claro">Claro</option>
                    <option value="TIM">TIM</option>
                    <option value="OI">OI</option>
                    <option value="Nextel">Nextel</option>
                    <option value="Algar">Algar</option>
                  </select>
                </Col>
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="celular">Celular</label>
                  <InputMask
                    id="celular"
                    name="celular"
                    type="text"
                    placeholder="Celular"
                    value={celularRecarga}
                    mask="(99) 99999-9999"
                    onChange={(e) => setCelularRecarga(e.target.value)}
                    onBlur={validarCelular}
                  ></InputMask>
                </Col>
              </form>
            )}
            {premio.tipo === 'bivolt' && (
              <form className="form loja fundo-claro">
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="voltagem">Voltagem</label>
                  <select
                    id="voltagem"
                    name="voltagem"
                    placeholder="Voltagem"
                    value={voltagem}
                    onChange={(e) => alterarVoltagem(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione a voltagem
                    </option>
                    <option value="110V">110V</option>
                    <option value="220V">220V</option>
                  </select>
                </Col>
              </form>
            )}

            {premio.tipo === 'camiseta' && (
              <form className="form loja fundo-claro">
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="tamanhoCamiseta">Tamanho</label>
                  <select
                    id="tamanhoCamiseta"
                    name="tamanhoCamiseta"
                    placeholder="Tamanho"
                    value={tamanhoCamiseta}
                    onChange={(e) => setTamanhoCamiseta(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione o tamanho
                    </option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="G">G</option>
                    <option value="GG">GG</option>
                    <option value="XG">XG</option>
                  </select>
                </Col>
              </form>
            )}

            {premio.tipo === 'bermuda' && (
              <form className="form loja fundo-claro">
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="tamanhoBermuda">Tamanho</label>
                  <select
                    id="tamanhoBermuda"
                    name="tamanhoBermuda"
                    placeholder="Tamanho"
                    value={tamanhoBermuda}
                    onChange={(e) => setTamanhoBermuda(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione o tamanho
                    </option>
                    <option value="36">36</option>
                    <option value="38">38</option>
                    <option value="40">40</option>
                    <option value="42">42</option>
                    <option value="44">44</option>
                    <option value="46">46</option>
                    <option value="48">48</option>
                    <option value="50">50</option>
                    <option value="52">52</option>
                  </select>
                </Col>
              </form>
            )}

            {premio.tipo === 'calcado' && (
              <form className="form loja fundo-claro">
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="tamanhoCalcado">Tamanho</label>
                  <select
                    id="tamanhoCalcado"
                    name="tamanhoCalcado"
                    placeholder="Tamanho"
                    value={tamanhoCalcado}
                    onChange={(e) => setTamanhoCalcado(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione o tamanho
                    </option>
                    <option value="37">37</option>
                    <option value="38">38</option>
                    <option value="39">39</option>
                    <option value="40">40</option>
                    <option value="41">41</option>
                    <option value="42">42</option>
                    <option value="43">43</option>
                    <option value="44">44</option>
                  </select>
                </Col>
              </form>
            )}

            {premio.tipo === 'kit' && premio.codigo === 184 && (
              <form className="form loja fundo-claro">
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="opcaoKit184Camiseta">
                    Opção das Camisetas
                  </label>
                  <select
                    id="opcaoKit184Camiseta"
                    name="opcaoKit184Camiseta"
                    placeholder="Opção"
                    value={opcaoKit184Camiseta}
                    onChange={(e) => setOpcaoKit184Camiseta(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione o tamanho
                    </option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="G">G</option>
                    <option value="GG">GG</option>
                    <option value="XG">XG</option>
                  </select>
                </Col>
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="opcaoKit184Calca">Opção da Calça</label>
                  <select
                    id="opcaoKit184Calca"
                    name="opcaoKit184Calca"
                    placeholder="Opção"
                    value={opcaoKit184Calca}
                    onChange={(e) => setOpcaoKit184Calca(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione o tamanho
                    </option>
                    <option value="36">36</option>
                    <option value="38">38</option>
                    <option value="40">40</option>
                    <option value="42">42</option>
                    <option value="44">44</option>
                    <option value="46">46</option>
                    <option value="48">48</option>
                    <option value="50">50</option>
                    <option value="52">52</option>
                  </select>
                </Col>
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="opcaoKit184Bermuda">Opção da Bermuda</label>
                  <select
                    id="opcaoKit184Bermuda"
                    name="opcaoKit184Bermuda"
                    placeholder="Opção"
                    value={opcaoKit184Bermuda}
                    onChange={(e) => setOpcaoKit184Bermuda(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione o tamanho
                    </option>
                    <option value="36">36</option>
                    <option value="38">38</option>
                    <option value="40">40</option>
                    <option value="42">42</option>
                    <option value="44">44</option>
                    <option value="46">46</option>
                    <option value="48">48</option>
                    <option value="50">50</option>
                    <option value="52">52</option>
                  </select>
                </Col>
              </form>
            )}

            {premio.tipo === 'kit' && premio.codigo === 185 && (
              <form className="form loja fundo-claro">
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="opcaoKit185Camiseta">
                    Opção das Camisetas
                  </label>
                  <select
                    id="opcaoKit185Camiseta"
                    name="opcaoKit185Camiseta"
                    placeholder="Opção"
                    value={opcaoKit185Camiseta}
                    onChange={(e) => setOpcaoKit185Camiseta(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione o tamanho
                    </option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="G">G</option>
                    <option value="GG">GG</option>
                    <option value="XG">XG</option>
                  </select>
                </Col>
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="opcaoKit185Calca">Opção das Calças</label>
                  <select
                    id="opcaoKit185Calca"
                    name="opcaoKit185Calca"
                    placeholder="Opção"
                    value={opcaoKit185Calca}
                    onChange={(e) => setOpcaoKit185Calca(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione o tamanho
                    </option>
                    <option value="36">36</option>
                    <option value="38">38</option>
                    <option value="40">40</option>
                    <option value="42">42</option>
                    <option value="44">44</option>
                    <option value="46">46</option>
                    <option value="48">48</option>
                    <option value="50">50</option>
                    <option value="52">52</option>
                  </select>
                </Col>
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="opcaoKit185Jaqueta">Opção da Jaqueta</label>
                  <select
                    id="opcaoKit185Jaqueta"
                    name="opcaoKit185Jaqueta"
                    placeholder="Opção"
                    value={opcaoKit185Jaqueta}
                    onChange={(e) => setOpcaoKit185Jaqueta(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione o tamanho
                    </option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="G">G</option>
                    <option value="GG">GG</option>
                    <option value="XG">XG</option>
                  </select>
                </Col>
              </form>
            )}

            {premio && premio.tipo && premio.tipo.startsWith('disco') && (
              <form className="form loja fundo-claro">
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="gramagemDisco">Gramagem do Disco</label>
                  <select
                    id="gramagemDisco"
                    name="gramagemDisco"
                    placeholder="Gramagem"
                    value={gramagemDisco}
                    onChange={(e) => setGramagemDisco(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione a gramagem
                    </option>
                    {getGramagemOptions(premio.tipo).map((gramagem) => (
                      <option key={gramagem} value={gramagem}>
                        {gramagem}
                      </option>
                    ))}
                  </select>
                </Col>
              </form>
            )}

            {premio.tipo === 'credito' && !cartao && (
              <>
                <Col>
                  <span className="premio-subtitulo">
                    <b>Cartão Mastercard® Clube Pro Pintor</b>
                  </span>
                </Col>

                <Col className="text-justify">
                  <span>
                    Você ainda não possui um cartão Mastercard® Clube Pro
                    Pintor.
                  </span>
                  <br />
                  <span>
                    Será enviado um envelope contendo o seu cartão Mastercard®
                    Clube Pro Pintor, já com o valor deste resgate.
                  </span>
                  <br />
                  <span>
                    Após desbloquear seu cartão guarde-o para seus próximos
                    resgates. É permitido 1 (um) cartão por CPF.
                  </span>
                  <br />
                  <span>
                    Os próximos resgates em dinheiro, serão creditados
                    automaticamente neste mesmo cartão.
                  </span>
                  <br />
                  <span>
                    Por medida de segurança seu cartão estará bloqueado, após
                    receber o envelope, siga as instruções para desbloquear o
                    seu cartão.
                  </span>
                  <br />
                  <span>
                    O desbloqueio do seu cartão pode levar até 24h úteis após a
                    solicitação.
                  </span>
                  <br />
                  <span>
                    Em caso de perda ou roubo, solicite o bloqueio do seu
                    cartão.
                  </span>
                  <br />
                  <br />
                  <span>
                    Importante verificar se o nome digitado é o do titular do
                    CPF cadastrado. Caso o nome esteja incorreto, a MASTERCARD®
                    solicitará os dados novamente.
                  </span>
                  <br />
                  <br />
                </Col>

                <form className="form loja fundo-claro">
                  <Col className="d-flex flex-column" xs={8} sm={6}>
                    <label htmlFor="nome">Digite seu nome</label>
                    <input
                      type="text"
                      id="nomeCompleto"
                      placeholder="Nome Completo"
                      value={nomeCompleto}
                      onChange={(e) => setNomeCompleto(e.target.value)}
                    ></input>
                  </Col>
                </form>
              </>
            )}

            {premio.tipo === 'credito' &&
              cartao &&
              cartao.numero &&
              !cartao.entregue && (
                <>
                  <Col>
                    <span className="premio-subtitulo">
                      <b>Cartão Mastercard® Clube Pro Pintor</b>
                    </span>
                  </Col>

                  <Col className="text-justify">
                    <span>
                      SEU CARTÃO MASTERCARD® CLUBE PRO PINTOR JÁ ESTÁ A
                      CAMINHO.
                    </span>
                    <br />
                    <br />
                    <span>
                      Sua carga será depositada diretamente no cartão com final:{' '}
                      {cartao.numero.toString().slice(-4)}
                    </span>
                    <br />
                    <br />
                    <span>
                      Os próximos resgates em dinheiro serão creditados nesse
                      mesmo cartão. Continue resgatando normalmente.
                    </span>
                    <br />
                    <br />
                    <span>
                      Após desbloquear seu cartão guarde-o para seus próximos
                      resgates. É permitido 1 (um) cartão por CPF.
                    </span>
                    <br />
                    <br />
                    <span>Seu cartão é pessoal e intransferível.</span>
                  </Col>
                </>
              )}

            {premio.tipo === 'credito' &&
              cartao &&
              cartao.numero &&
              cartao.entregue && (
                <>
                  <Col>
                    <span className="premio-subtitulo">
                      <b>Cartão Mastercard® Clube Pro Pintor</b>
                    </span>
                  </Col>

                  <Col className="text-justify">
                    <span>
                      Você já possui um cartão Mastercard® Clube Pro Pintor
                    </span>
                    <br />
                    <br />
                    <span>
                      Sua carga será depositada diretamente no cartão com final:{' '}
                      {cartao.numero.toString().slice(-4)}
                    </span>
                    <br />
                    <br />
                    <span>
                      Após desbloquear seu cartão guarde-o para seus próximos
                      resgates. É permitido 1 (um) cartão por CPF.
                    </span>
                    <br />
                    <br />
                    <span>Seu cartão é pessoal e intransferível.</span>
                  </Col>
                </>
              )}
            <Col className="pt-5">
              <button
                className="btn-resgatar"
                onClick={() => setConfirmar(true)}
              >
                <i className="fas fa-shopping-cart"></i> RESGATAR
              </button>
            </Col>
          </Col>
        </Row>
      ) : null}
      {confirmar && !resgatando ? (
        <Row>
          <Col xs={12} lg={6} className="p-3">
            <img
              className="w-100"
              alt={premio.nome}
              src={premio.diretorioFoto}
            ></img>
          </Col>
          <Col
            xs={12}
            lg={6}
            name={premio.codigo}
            className="d-flex justify-content-center align-items-center p-3 flex-column"
          >
            <ConfimarEndereco
              state={parceiro}
              premio={premio}
              qtde={qtde}
              resgatar={resgatar}
              setConfirmar={setConfirmar}
              pontosBase={pontosBase}
              updateUser={updateUser}
            />
            {/* <span className="p-2 text-center">
              Tem certeza que deseja resgatar{' '}
              <span className="red">
                <b>{`${qtde} x ${premio.nome}`}</b>
              </span>{' '}
              por{' '}
              <span className="red">
                <b>{Number(pontosBase * qtde).toLocaleString('pt-BR')}</b>{' '}
                pontos?
              </span>
            </span>
            <div className="text-center">
              <button className="btn btn-success m-2" onClick={resgatar}>
                <i className="fas fa-check"></i> SIM
              </button>
              <button
                className="btn btn-danger m-2"
                onClick={() => setConfirmar(false)}
              >
                <i className="fas fa-times"></i> NÃO
              </button>
            </div> */}
          </Col>
        </Row>
      ) : null}
      {resgatando && !confirmar ? (
        <Row>
          <Col xs={12} lg={6} className="p-3">
            <img
              className="w-100"
              alt={premio.nome}
              src={premio.diretorioFoto}
            ></img>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="d-flex justify-content-center align-items-center p-3 flex-column"
          >
            <span className="p-2">
              Resgatando <i className="fas fa-circle-notch fa-spin"></i>
            </span>
          </Col>
        </Row>
      ) : null}
      <Accordion defaultActiveKey="0">
        <div>
          <Accordion.Toggle as={Col} variant="link" eventKey="0">
            <div className="mt-3">
              <h5 className="mb-3">OUTROS PRÊMIOS</h5>
              <hr className="hr-loja mt-0" />
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Col xs={12}>
              <Slider {...settings}>
                {premios.map((premio) => {
                  return (
                    <div key={premio.codigo} id={premio.codigo} name="premio">
                      <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <Link to={`/premio/${premio.codigo}`}>
                          <img
                            alt={premio.nome}
                            className="w-100"
                            src={premio.diretorioFoto}
                          ></img>
                        </Link>

                        <span className="card-premio-nome">{premio.nome}</span>
                        <span>
                          <b>
                            {Number(premio.pontosWeb).toLocaleString('pt-BR')}{' '}
                            pontos
                          </b>
                        </span>
                        <Link to={`/premio/${premio.codigo}`}>
                          <button className="btn-resgatar mt-1">
                            RESGATAR
                          </button>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </Col>
          </Accordion.Collapse>
        </div>
      </Accordion>
      <div style={{ marginTop: 64 }}>
        <Accordion defaultActiveKey="1">
          <div className="mt-3">
            <Accordion.Toggle as={Col} variant="link" eventKey="1">
              <div className="mt-3">
                <h5 className="mb-3">AVALIAÇÕES</h5>
                <hr className="hr-loja mt-0" />
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <div style={{ padding: '0 16px' }}>
                {permitidoAvaliar && (
                  <RateAward
                    foto={parceiro.foto}
                    nome={parceiro.nome}
                    idPremio={codigo}
                  />
                )}
                {avaliacoes?.length > 0 ? (
                  avaliacoes.map((avaliacao) => (
                    <div key={avaliacao.id} className="container">
                      <div className="container-header">
                        <ProfilePicture foto={avaliacao.Parceiro.foto} />
                        <div className="profile-info">
                          <span className="name">
                            {avaliacao.Parceiro.nome}
                          </span>
                          <span className="info">
                            Avaliado em:{' '}
                            {new Date(avaliacao.criadoEm).toLocaleDateString(
                              'pt-BR',
                              {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              }
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="container-content">
                        <StarRating nota={avaliacao.nota} disabled />
                        <p>{avaliacao.descricao}</p>
                        {avaliacao.fotos && avaliacao.fotos.length > 0 && (
                          <div className="img-list-wrapper">
                            {avaliacao.fotos.map((foto) => (
                              <button
                                className="img-btn"
                                onClick={() => {
                                  setSelectedPicture(foto);
                                  setShowModal(true);
                                }}
                              >
                                <img
                                  src={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${foto}`}
                                />
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div>Nenhuma avaliação feita. </div>
                )}
              </div>
            </Accordion.Collapse>
          </div>
        </Accordion>
      </div>
      <ModalMidia
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setSelectedPicture('');
        }}
        diretorioArquivo={selectedPicture}
        tipo="image"
      />
    </Container>
  );
}

function StarRating(props) {
  const MAX_RATING = 5;
  const [rating, setRating] = useState(Number(props.nota));
  const [hover, setHover] = useState(null);

  return (
    <div className="star-rating">
      <span>{rating}</span>
      <div className="star-wrapper">
        {[...Array(MAX_RATING)].map((star, index) => {
          const currentRating = index + 1;
          return (
            <label>
              <input
                type="radio"
                name="rating"
                value={currentRating}
                onClick={() => {
                  if (!props.disabled) {
                    setRating(currentRating);
                    if (props.onClick) {
                      props.onClick(currentRating);
                    }
                  }
                }}
              />
              <div
                className="icon-wrapper"
                style={{ cursor: props.disabled ? 'default' : 'pointer' }}
                onMouseEnter={() => {
                  if (!props.disabled) setHover(currentRating);
                }}
                onMouseLeave={() => {
                  if (!props.disabled) setHover(null);
                }}
              >
                {currentRating <= (hover || rating) ? (
                  <i key={currentRating} className="fas fa-star" />
                ) : (
                  <i key={currentRating} className="far fa-star" />
                )}
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
}

function RateAward(props) {
  const [nota, setNota] = useState(5);
  const [files, setFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('Procurar...');
  const [description, setDescription] = useState('');

  const postRate = async () => {
    const body = new FormData();
    body.append('idPremio', props.idPremio);
    body.append('nota', nota);
    body.append('descricao', '');
    for (let file of files) {
      body.append('arquivos', file);
    }

    const resultado = await api.post('/api/avaliacoes_premio', body);
    if (resultado && resultado.status === 200) {
      toast.success('Avaliação postada com sucesso.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      window.location.reload();
    }
  };

  return (
    <div className="rate-wrapper">
      <div className="container-header">
        <ProfilePicture foto={props.foto} />
        <div className="profile-info">
          <span className="name">{props.nome}</span>
        </div>
      </div>
      <div className="form-wrapper">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            postRate();
          }}
        >
          <div className="form-group">
            <label htmlFor="nota">Nota: </label>
            <StarRating nota={nota} onClick={setNota} />
          </div>
          {/* <div className="form-group ">
            <label htmlFor="descricao">Descrição</label>
            <textarea
              maxLength={256}
              name="descricao"
              id="descricao"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div> */}
          <div className="form-group ">
            <label htmlFor="arquivos">Envie a foto do produto resgatado:</label>
            <input
              hidden
              type="file"
              id="arquivos"
              name="arquivos"
              multiple
              accept="image/*"
              onChange={(e) => {
                setFiles(e.target.files);
                e.target.files.length > 0
                  ? setUploadStatus(
                      `${e.target.files.length} arquivos(s) selecionado(s)`
                    )
                  : setUploadStatus('Procurar...');
              }}
            />
            <input
              className="btn-file"
              id="botao"
              type="button"
              value={uploadStatus}
              onClick={(e) => {
                document.getElementById('arquivos').click();
              }}
            />
          </div>
          <div className="btn-wrapper">
            <button type="submit" className="btn-resgatar">
              Avaliar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

function ProfilePicture(props) {
  return (
    <img
      src={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${props.foto}`}
      className="profile-img"
    />
  );
}